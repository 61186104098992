import { ELocalStorageKey } from '../types/enums';
import request, { IWorkspaceResponse } from '/@/api/http/request';
import requestO from '/@/api/http/requestother';
import { mapLayers } from '/@/constants/mock-layers';
import { elementGroupsReq, PostElementsBody, PutElementsBody } from '/@/types/mapLayer';
const PREFIX = '/map/api/v1';
const workspace_id = localStorage.getItem(ELocalStorageKey.WorkspaceId);
type UnknownResponse = Promise<IWorkspaceResponse<unknown>>;
// get elements group
// export const getLayers = async (reqParams: elementGroupsReq): UnknownResponse => {
//   const url = `${PREFIX}/workspaces/${workspace_id}/element_groups`
//   const result = await request.get(url, {
//     params: {
//       group_id: reqParams.groupId,
//       is_distributed: reqParams.isDistributed
//     },
//   })
//   return result.data
// }
export const getLayers = async (reqParams: elementGroupsReq): UnknownResponse => {
	return mapLayers;
};

// Get elements groups request
export const getElementGroupsReq = async (body: elementGroupsReq): Promise<IWorkspaceResponse<any>> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + '/element-groups';
	const result = await request.get(url, body);
	return result.data;
};
// add element
export const postElementsReq = async (pid: string, body: PostElementsBody): Promise<IWorkspaceResponse<{ id: string }>> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/element-groups/${pid}/elements-web`;
	const result = await request.post(url, body);
	return result.data;
};
export const postElementsReqBak = async (pid: string, body: PostElementsBody): Promise<IWorkspaceResponse<{ id: string }>> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/element-groups/${pid}/elements`;
	const result = await request.post(url, body);
	return result.data;
};
// Update map element request
export const updateElementsReq = async (id: string, body: PutElementsBody): Promise<IWorkspaceResponse<{ id: string }>> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/elements/${id}`;
	const result = await request.put(url, body);
	return result.data;
};
// Delete map element
export const deleteElementReq = async (id: string, body: {}): Promise<any> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/elements/${id}`;
	const result = await request.delete(url, body);
	return result.data;
};

// Delete layer elements
export const deleteLayerEleReq = async (id: string, body: {}): Promise<any> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/element-groups/${id}/elements`;
	const result = await request.delete(url, body);
	return result.data;
};
// export layer kml file
export const exportKml = async (body: any): Promise<any> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/export`;
	const result = await request.post(url, body, { responseType: 'blob' });
	if (result.data.type === 'application/vnd.google-earth.kmz+xml') {
		const href = URL.createObjectURL(result.data);
		const box = document.createElement('a');
		box.download = 'Layer导出.kml';
		box.href = href;
		box.click();
		const reader = new FileReader();
		/* reader.onload = function (e) {
			const href = URL.createObjectURL(res.data)
			const box = document.createElement('a')
			box.download = 'Layer导出.xml'
			box.href = href
			box.click()
			// message.error(result.message)
		}; */
		// reader.readAsText(result.data, 'utf-8');
	} else {
		return result.data;
	}
};
// import layer kml file
export const importKml = async (body: {}): Promise<any> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/import`;
	const result = await request.post(url, body);
	return result.data;
};
// add layer group
export const addElementGroupsReq = async (group_name: string, group_id?: string, is_distributed = false): Promise<IWorkspaceResponse<any>> => {
	const url =
		`${PREFIX}/workspaces/` +
		workspace_id +
		`/element-groups?is_distributed=${is_distributed}&group_name=${group_name}${group_id ? '&group_id=' + group_id : ''}`;
	const result = await request.put(url);
	return result.data;
};
// move layer element
// source_group_id  源组id
// target_group_id  目标组id
export const moveElementReq = async (element_id: string, source_group_id: string, target_group_id: string): Promise<IWorkspaceResponse<any>> => {
	const url =
		`${PREFIX}/workspaces/` + workspace_id + `/elements/${element_id}/move?source_group_id=${source_group_id}&target_group_id=${target_group_id}`;
	const result = await request.put(url);
	return result.data;
};
export const moveElementsReq = async (element_ids: string, source_group_id: string, target_group_id: string): Promise<IWorkspaceResponse<any>> => {
	const url =
		`${PREFIX}/workspaces/` + workspace_id + `/elements/moves?source_group_id=${source_group_id}&target_group_id=${target_group_id}&element_ids=${element_ids}`;
	const result = await request.put(url);
	return result.data;
};
// del layer group
export const delElementGroupsReq = async (id: string): Promise<IWorkspaceResponse<any>> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/element-groups?group_id=${id}`;
	const result = await request.delete(url);
	return result.data;
};
// del layer group
export const delGroupsReq = async (id: string): Promise<IWorkspaceResponse<any>> => {
	const url = `${PREFIX}/workspaces/` + workspace_id + `/elements/batch-delete?group_ids=${id}`;
	const result = await request.delete(url);
	return result.data;
};

export const delTaskByName = async (caozuo: string,taskId:string): Promise<IWorkspaceResponse<any>> => {
	const url = `/vx/task/${caozuo}/${taskId}`;
	
	const tenantId = localStorage.getItem(ELocalStorageKey.TenantId);
	const result = await request.post(url,{
		"tenant-id": tenantId,
	});
	return result.data;
};

export const getTaskByName = async (taskId:string): Promise<IWorkspaceResponse<any>> => {
	const url = `/vx/task/${taskId}`;
	const result = await request.get(url);
	return result.data;
};

export const getMapConfig = async function (): Promise<IWorkspaceResponse<any>> {
	let workSpaceId = localStorage.getItem('workspace_id');
	const url = `/model/${workSpaceId}/file/config`;
	const result = await request.get(url);
	return result.data;
};

export const getConfigFileUrl = async (url: string) => {
	const result = await requestO.get(url);
	return result.data;
	// return requestO.get(url);
};
